@use 'sass:color';

$background: #ecf8f8;
$text: #1f271b;
$text-light: $background;
$background-dark: color.adjust($text, $lightness: -8%);
$border-dark: color.adjust($text, $lightness: -50%);
$border-light: color.adjust($text-light, $lightness: -60%);
$shadow: 0 0 10px rgba(0, 0, 0, 30%);
$danger: #d90429;
$warning: #ffb86c;
$success: #6fcf97;
$border-radius: 0.8rem;

@mixin dark-mode {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin light-theme {
  border-color: $border-light;
  background-color: $background;
  color: $text;
}

@mixin dark-theme {
  border-color: $border-dark;
  background-color: $background-dark;
  color: $text-light;
}

@mixin theme {
  @include light-theme;

  @include dark-mode {
    @include dark-theme;
  }
}

@mixin inverted-theme {
  @include dark-theme;

  @include dark-mode {
    @include light-theme;
  }
}

@mixin glass($alpha: 0.3, $color: null) {
  border-color: color.change($border-dark, $alpha: 0.5);
  backdrop-filter: blur(10px);
  background-color: color.change($background, $alpha: $alpha);
  box-shadow: $shadow;

  @include dark-mode {
    border-color: color.change($border-light, $alpha: 0.5);
    color: $text-light;
  }

  @if $color {
    background-color: color.change($color, $alpha: $alpha);
  } @else {
    background-color: color.change($background, $alpha: $alpha);

    @include dark-mode {
      background-color: color.change($background-dark, $alpha: $alpha);
    }
  }
}
