@use '../../../../../styles/screen';
@use '../../../../../styles/shared';

.form {
  width: 100%;

  @include screen.md {
    display: block;
    width: 25rem;
  }
}

.preview {
  display: block;
  width: 100%;
}

.input {
  width: 100%;
  margin-top: 1rem;
}

.button {
  position: fixed;
  z-index: 10;
  right: 2rem;
  bottom: 2rem;
}
