// Small tablets and large smartphones (landscape view)
$sm: 576px;

@mixin sm {
  @media (min-width: $sm) {
    @content;
  }
}

// Small tablets (portrait view)
$md: 768px;

@mixin md {
  @media (min-width: $md) {
    @content;
  }
}

// Tablets and small desktops
$lg: 992px;

@mixin lg {
  @media (min-width: $lg) {
    @content;
  }
}

// Large tablets and desktops
$xl: 1200px;

@mixin xl {
  @media (min-width: $xl) {
    @content;
  }
}
