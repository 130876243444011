@use '../styles/screen';
@use '../styles/shared';

.modal {
  @include shared.glass(0.5);

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 4rem 1rem;
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  transition: opacity 0.2s ease;

  @include screen.md {
    padding: 4rem 2rem;
  }

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}
